import { message } from 'ant-design-vue'

import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'

const icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m19.95,5.536l-3.485-3.485c-1.322-1.322-3.08-2.05-4.95-2.05h-4.515C4.243,0,2,2.243,2,5v14c0,2.757,2.243,5,5,5h10c2.757,0,5-2.243,5-5v-8.515c0-1.87-.728-3.627-2.05-4.95Zm-1.414,1.414c.318.318.587.671.805,1.05h-4.341c-.551,0-1-.449-1-1V2.659c.379.218.733.487,1.05.805l3.485,3.485Zm1.464,12.05c0,1.654-1.346,3-3,3H7c-1.654,0-3-1.346-3-3V5c0-1.654,1.346-3,3-3h4.515c.163,0,.325.008.485.023v4.977c0,1.654,1.346,3,3,3h4.977c.015.16.023.322.023.485v8.515Zm-4.293-4.519c.391.391.391,1.023,0,1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-1.293-1.293v4.398c0,.552-.448,1-1,1s-1-.448-1-1v-4.398l-1.293,1.293c-.391.391-1.023.391-1.414,0s-.391-1.023,0-1.414l1.614-1.614c1.154-1.154,3.032-1.154,4.187,0l1.614,1.614Z"/></svg>'
const key = 'ckeditor_loader'

class FGenerate extends Plugin {
    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add('fgenerate', () => {
            const button = new ButtonView();

            button.set({
                label: 'Генерация документа',
                withText: false,
                tooltip: 'Генерация документа',
                icon
            })

            button.on('execute', () => {
                message.loading({ content: 'Генерация файла', key, duration: 0 })
                const data = editor.getData()

                if(data) {
                    console.log(data, 'data')

                    setTimeout(() => {
                        message.success({ content: 'Файл сгенерирован', key, duration: 5 })
                    }, 5000)
                } else {
                    message.warning({ content: 'Поле ввода не заполнено', key, duration: 3 })
                }
            })

            return button
        })
    }
}

export default FGenerate