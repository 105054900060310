import CKEditor from '@ckeditor/ckeditor5-vue2'

export default {
    components: {
        ckeditor: CKEditor.component,
    },
    props: {
        value: {
            type: String
        },
        uploadUrl: {
            type: String,
            default: "/api/v1/common/upload_for_editor/"
        },
        taskId: {
            type: String,
            default: ''
        },
        injectItems: {
            type: Array,
            default: () => []
        },
        initFocus: {
            type: Boolean,
            default: false
        },
        enterShifthHand: {
            type: Function,
            default: () => {}
        },
        commentEditor: {
            type: Boolean,
            default: false
        }
    },
    created() {
        if(this.injectItems?.length) {
            this.editorConfig.toolbar.items = this.editorConfig.toolbar.items.concat(this.injectItems)
        }
    },
    methods: {
        changeHandler(event) {
            this.$emit('input', event)
        },
        getFeedItems(queryText) {
            if(!this.taskId)
                return null
            return new Promise( resolve => {
                
                setTimeout(async () => {
                    let itemsToDisplay = await this.getSubtaskList(this.taskId)
                    itemsToDisplay = itemsToDisplay 
                        // Filter out the full list of all items to only those matching the query text.
                        .filter( isItemMatching )
                        // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
                        .slice( 0, 10 )

                    resolve( itemsToDisplay )
                }, 100 )
            } )

            function isItemMatching(item) {
                const searchString = queryText.toLowerCase();
                return (
                    String(item.name).toLowerCase().includes( searchString ) ||
                    String(item.id).toLowerCase().includes( searchString )
                )
            }
        },
        customItemRenderer( item ) {
            const itemElement = document.createElement( 'div' );
            // itemElement.classList.add('ck-autocomplete-list');
            // itemElement.id = `mention-list-item-id-${ item.userId }`;
            
            const truncateLength = 10
            let taskName = item.name
            if(taskName.length > truncateLength)
                taskName = taskName.slice(0, truncateLength) + '...'

            itemElement.textContent = item.id;
            // itemElement.setAttribute('href', item.counter)1
            // const usernameElement = document.createElement( 'span' );

            // usernameElement.classList.add( 'custom-item-username' );
            // usernameElement.textContent = item.id;

            // itemElement.appendChild( usernameElement );

            return itemElement;
        },
        async getSubtaskList(parentId) {
            const params = {
                parent: parentId,
                page_size: 'all'
            }

            try {
                const { data } = await this.$http('/tasks/task/list/', { params: params })
                const subtaskList = data.results.map(item => {
                    return {
                        id: `#${item.counter} ${item.name}`,
                        objectType: 'task',
                        objectId: item.id,
                        name: item.name,
                    }
                })
                return subtaskList
            } catch(error) {
                console.log(error)
            }
        },
    }
}